<template>
  <p class="nk-text-muted text-center">{{$t('pages.invoices.footer_text1')}}</p>
  <hr class="bg-light"/>
  <div class="font-weight-bold nk-text-muted">{{$t('pages.invoices.footer_text2')}}</div>
  <p class="nk-text-muted">{{$t('pages.invoices.footer_text3')}}</p>
</template>

<script>
export default {
name: "InvoiceFooter"
}
</script>

<style scoped>

</style>
